.headline {
    font-size: 35px;
    font-weight: bold;
    padding-left: 15px;
    letter-spacing: 8px;
}

.topline {
    color: #000;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
    padding: 20px;
    padding-top: 35px;
    text-align: center;
}

.mainLogo {
    max-width: 300px !important;
    height: auto!important;
    width: 100%!important;
}

.full-height{
    height: 100%;
}

.modalInner{
    position: relative; height: auto;
}

.full-height{
    position: absolute;
    left: 0;
    width: 20%;
    top: 0;
    height: 100%;
  }

.mainOptions {
    position: absolute;
    top: 75px;
    right: 68px;
}

.mainOptions img{
    margin-right: 5px;
}

.rotate180 {
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Safari 3-8 */
    transform: rotate(180deg);
}

.midiStatusIcon {
    width: 10px;
    float: right;
    height: 10px;
    margin-top: 2px;
    border: solid 1px;
    border-radius: 17px;
}

.midiStatus {
    padding-top: 8px;
    margin-right: 15px;
    display: inline-block;
    width: 55px;
    font-size: 10px;
}

.configChord {
    cursor: pointer;
    display: inline-block;
    margin-top: -10px;
    border: solid 2px;
}

.dropZone {
    border-top: solid 1px;
    padding: 20px;
    border-color: #e7e7e7;
    min-height: 110px;
    display: inline-block;
    width: 100%;
}

.dropZoneWrap{
    display: inline-block;
    width: calc(100% - 30px);
}

.dropZoneTrigger {
    position: relative;
    background-color: #fff;
    padding-left: 34px;
    font-size: 10px;
    width: 170px;
    cursor: pointer;
    top: -9px;
    font-weight: bold;
}

.dropZoneTrigger img{
    margin-right: 5px;
}

.dropZone h6 {
    font-weight: bold;
}

.active {
    border-color: #abc449 !important;
}

.midiActive {
    background-color: #abc449;
}

.statusDescr {
    float: left;
}

.topline img {
    height: 30px;
    width: auto;
    margin-bottom: 5px;
}

html{
    touch-action: manipulation;
}

html,
body {
    font-family: 'Abel', sans-serif;
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
}

body a,
body a:hover {
    color: #ff008b;
}

.donate {
    margin-left: 25px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.grayscale {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    /* Firefox 3.5+ */
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(100%);
    /* Chrome 19+ & Safari 6+ */
}

.grayscale:hover {
    filter: none;
    -webkit-filter: grayscale(0%);
}

body {
    background-image: url("./img/bg.jpg");
    background-size: cover;
    background-color: #d7d7d7;
}

@-webkit-keyframes effect_dylan {
    50% {
        -webkit-transform: scale(1.5, 1.5);
        transform: scale(1.5, 1.5);
        opacity: 0;
    }
    99% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 1;
    }
}

@keyframes effect_dylan {
    50% {
        -webkit-transform: scale(1.5, 1.5);
        transform: scale(1.5, 1.5);
        opacity: 0;
    }
    99% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 1;
    }
}

#root {
    border-radius: 5px;
    background-color: #fff;
    padding: 0px;
    margin: 40px;
    -webkit-box-shadow: 0px 0px 31px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 31px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 31px -7px rgba(0, 0, 0, 0.75);
}

.App {
    padding: 0px;
}

.Modal h1 {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 35px;
}

.Modal h1 img {
    width: 38px;
    padding-bottom: 5px;
}

.Modal h2 img {
    width: 25px;
    margin-right: 10px;
}

.Modal h2 {
    font-size: 16px;
    font-weight: bold;
}

.Modal label {
    margin-top: 20px;
}

.Modal ul {
    margin-top: 20px;
    padding-left: 25px;
}

.Modal li {
    list-style-image: url("./icons/angle-right.svg");
}

.liSpacer li {
    margin-bottom: 10px;
}

.Modal li img {
    width: 10px;
    margin-right: 10px;
}

.modal-btn {
    float: right;
    clear: both;
    text-transform: uppercase;
    font-weight: bold;
    background-color: transparent;
    border: solid 1px;
    cursor: pointer;
}

.red {
    background-color: #ff5376 !important;
}

.modal-warn-btn {
    float: right;
    text-transform: uppercase;
    font-weight: bold;
    background-color: transparent;
    border: solid 1px;
    cursor: pointer;
}

.warningModal {
    width: 400px;
    margin: auto;
}

.btnwrapper{
    margin-top: 20px;
}

.modal-btn img {
    margin-bottom: 2px;
}

.scrollarea {
    width: 100%;
    height: 66vh;
    padding: 15px;
}

.Modal {
    position: absolute;
    font-size: 14px;
    top: 9vh;
    left: 80px;
    right: 80px;
    border-radius: 5px;
    padding: 45px;
    background-color: #fff;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #292929;
}

.OverlayStart {
    background-image: url("./img/bg.jpg");
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.scaleTitle {
    font-size: 12px;
}

.chordButton {
    text-align: center;
    padding-top: 6px;
    margin: 2px;
    float: left;
    cursor: pointer;
    width: 50px;
    min-width: 24px;
    height: 50px;
    border: solid 1px;
    color: #000;
    font-weight: bold;
    border-radius: 3px;
    background-color: #fff;
    border-color: #efefef;
}

.btn-wrap {
    float: left;
}

.btn-bg {
    background-color: #abc449;
    width: 50px;
    position: relative;
    height: 50px;
    margin-top: 2px;
    left: -1px;
    height: 45px;
    top: -48px;
    z-index: -1;
    border-radius: 32px;
}

.clickable {
    cursor: pointer;
}

html {
    touch-action: manipulation;
}

.chordButton:active>div.btn-bg {
    -webkit-animation: effect_dylan 0.8s ease-out infinite;
    animation: effect_dylan 0.8s ease-out infinite;
}

.chordButton:active {
    -webkit-box-shadow: 0px 0px 114px 1px #abc449;
    -moz-box-shadow: 0px 0px 114px 1px #abc449;
    box-shadow: 0px 0px 114px 1px #abc449;
    border: solid 2px;
    border-color: #abc449;
}

.chordButton span[class^='ion'] {
    position: relative;
}

.chordButtonFat {
    padding-top: 28px;
    width: 80px;
    height: 80px;
    text-align: center;
    float: right;
    cursor: pointer;
    border: solid 1px;
    color: #000;
    font-weight: bold;
    border-radius: 3px;
    background-color: #fff;
    border-color: #efefef;
}

.chordButtonFat:active {
    -webkit-box-shadow: 0px 0px 114px 1px #abc449;
    -moz-box-shadow: 0px 0px 114px 1px #abc449;
    box-shadow: 0px 0px 114px 1px #abc449;
    border: solid 2px;
    border-color: #abc449;
}

.editChordsBtn {
    margin-bottom: 5px;
}

.midiMappedButtons {
    width: 30px;
    float: right;
}

.deleteAllChordsBtn {
    width: 30px;
    height: 30px;
}

.deleteAllChordsBtn img {
    width: 30px;
    height: 30px;
    border: solid 2px;
    cursor: pointer;
}

.editChordsBtn img {
    width: 30px;
    height: 30px;
    border: solid 2px;
    cursor: pointer;
    padding: 2px;
}

.editOptions {
    padding: 5px;
}

.editOptions img {
    width: 15px;
    height: 16px;
    padding: 1px;
    float: left;
}

.logo {
    text-align: left;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 35px;
    text-align: center;
}

.logo img {
    margin-top: -5px;
    margin-right: 10px;
    width:100%;
    max-width: 300px;
}

.chordRow button {
    float: left;
    clear: both;
    padding: 20px;
}

.clear {
    clear: both;
}

.chordType {
    width: 100%;
    font-size: 8px;
    padding: 2px;
}

.version {
    float: right;
    font-size: 10px;
}

.hide {
    display: none;
}

.chordWrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
}

.activeHover {
    background-color: #5abad5 !important;
}

.chordRow {
    flex: 1 1 0;
    padding: 5px;
}

.mapMsg {
    float: left;
    margin-left: 15px;
    font-style: italic;
    padding-top: 20px;
    padding-right: 15px;
}

.mapMsg img{
    margin-right: 10px;
}

.optionIcon {
    cursor: pointer;
    display: inline-block;
}

.optionIcon img {
    border: solid 2px;
    margin-top: -10px;
    width: 30px;
}

.config label {
    width: 100%;
}

.keys {
    float: left;
    height: 80px;
}

.buttonGroup {
    float: right;
}

.invertBox {
    font-size: 20px;
    font-weight: bold;
}

.invert ul {
    padding: 0;
}

.key {
    position: relative;
    float: left;
    border: solid 1px;
    width: 20px;
    height: 80px;
}

.keyWrapper {
    float: left;
}

.halfKeys {
    position: absolute;
}

.halfKey {
    z-index: 2;
    float: left;
    background-color: #474747;
    width: 12px;
    height: 55px;
    position: relative;
    top: 0px;
}

.activeKey {
    background-color: #5ad5ab;
}

.cFlat {
    left: 16px;
}

.dFlat {
    left: 24px;
}

.fFlat {
    left: 52px;
}

.gFlat {
    left: 60px;
}

.aFlat {
    left: 68px;
}

.col-md-4{
    padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .headline {
        font-size: 3vw;
    }

    .Modal{
        top: 15px;
        left: 15px;
        right: 15px;
        margin-bottom: 10px;
        padding: 20px;
    }

    .warningModal{
        width: 90vw;
    }

    #root{
        margin:15px;
    }


}

@media only screen and (max-width: 750px){
    .mainOptions{
        position: inherit;
    }

    .chordWrapper{
        display: grid;
        padding-bottom: 20px;
    }
}